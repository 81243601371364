.bg-success {
  background-color: #d1fae5 !important;
  color: #065f46;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}

.bg-primary {
  background-color: #dbeafe !important;
  color: #1e40af;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}

.bg-danger {
  background-color: #fee2e2 !important;
  color: #991b1b;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}
.bg-warning {
  background-color: #f4eeb9 !important;
  color: #72680d;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}

.loading-page-v {
  height: 100vh;
}

.loading-page-h {
  height: 100%;
}

.loading-page-h .css-4ndf5c-LoadingOverlayWrapperBase {
  margin-top: 50vh;
  margin-right: auto !important;
  margin-left: auto !important;
}

.page-title {
  color: var(--primary-font-color);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0px;
}

.page-subtitle {
  color: var(--secondary-font-color);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.main-container {
  background-color: var(--main-container-bg-color);
  width: 80%;
  margin-top: 62px;
  border-radius: 16px;
}

.padding-card-header {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

.detail-card-header {
  background-color: var(--row-table-bg-color) !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  border-bottom: 1px solid var(--row-table-bg-color);
  padding: 16px 24px;
}

.normal-button {
  background: var(--primary-button-color);
  border: 1px solid var(--primary-button-color);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 9px 13px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--primary-button-font-color);
  line-height: 14px;
}

.btn-primary {
  border: none;
  background: var(--primary-button-color);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 9px 13px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--primary-button-font-color);
}

.btn-primary:active {
  background-color: var(--primary-button-active-color);
  color: #ffffff;
}

.btn:hover {
  background-color: var(--primary-button-hover-color);
}

.btn:active {
  background-color: var(--primary-button-active-color);
  color: #ffffff;
}

.nav-active {
  border: none;
  background: var(--primary-button-color) !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--primary-button-font-color) !important;
  height: 36px !important;
}

.nav-active:hover {
  background-color: var(--primary-button-hover-color) !important;
}
.nav-active:active {
  background-color: var(--primary-button-active-color) !important;
  color: var(--primary-font-color) !important;
}

.nav-link {
  padding: 9px 13px !important;
}

.nav-active a {
  color: var(--primary-button-font-color) !important;
}

.nav-active a:hover,
a:active {
  color: var(--primary-button-font-color) !important;
}

.odd {
  background-color: var(--row-table-bg-color) !important;
}

.even {
  background-color: var(--table-header-background) !important;
}

.formDetails {
  background-color: var(--table-header-background) !important;
}

.ag-ltr .ag-cell {
  border: none;
}

.expand_icon {
  position: absolute;
  left: 259px;
  top: 17px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  z-index: 3;
  background-color: #ffffff;
  box-shadow: 3px 3px 3px #aeaeae;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: left 0.4s linear;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .main-container {
    width: 95%;
  }
  .pl-sm-0 {
    padding-left: 0px !important;
  }
  .pr-sm-0 {
    padding-right: 0px !important;
  }

  .pl-sm-0 {
    padding-left: 0px;
  }
}

.mainContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: 0;
}

.screenContainer {
  min-width: 200px;
  flex: 1;
  height: 102vh;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  padding: 0;
  background-color: #ffffff;
}
