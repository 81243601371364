.attachment-item {
  width: 64px;
  height: 64px;
  padding: 0px;
  border-radius: 6px;
  margin-right: 24px;
  position: relative;
}

.attachment-img {
  width: 100%;
  border-radius: 6px;
  max-height: 64px;
  min-height: 64px;
}

.attachment-delete {
  left: 50px;
  top: -10px;
  background: #dc2626;
  border-radius: 50%;
  position: absolute;
  padding: 1px 6px;
  cursor: pointer;
  width: 22px;
  height: 22px;
}

.attachment-delete img {
  padding-bottom: 8px;
}
