.modal {
  background: rgba(227, 227, 227, 0.5);
  backdrop-filter: blur(2px);
}

.modal-content {
  background-color: var(--modal-background-color);
}

.post-editor-modal {
  border: none;
  --bs-modal-border-color: none;
}

.post-editor-modal .modal-header {
  padding-top: 32px;
  padding-right: 32px;
  padding-left: 32px;
  display: block;
  border: none;
}

.editor-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: var(--primary-font-color);
  font-family: "inter";
}

.editor-sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--secondary-font-color);
  font-family: "inter";
}

.post-editor-modal .modal-body {
  padding-right: 32px;
  padding-left: 32px;
}

.editor-title-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #374151;
  margin-bottom: 4px;
  font-family: "Inter";
}

.editor-control {
  background-color: var(--modal-background-color);
  border: 1px solid var(--text-input-border-color);
  color: var(--primary-font-color);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 9px 13px;
  font-family: "Inter" !important;
}

.form-select {
  background-color: var(--input-background-color);
  border: 1px solid var(--text-input-border-color);
  color: var(--primary-font-color);
  ::placeholder {
    color: var(--secondary-font-color);
  }
}

.form-select:focus {
  box-shadow: none;
}

.mt-24 {
  margin-top: 24px;
}

.rdw-editor-wrapper {
  padding-right: 0px;
  padding-left: 0px;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.rdw-editor-toolbar {
  border: none;
}

.rdw-editor-main {
  height: 250px;
  overflow: hidden;
  padding: 13px;
}

.post-editor-modal .modal-footer {
  border: none;
  background: var(--modal-background-color);
}

.tags-dropdown {
  background: #ffffff;
  border: 1px solid #d1d5db !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px !important;
}

.filter-dropdown .react-dropdown-select-option .react-dropdown-select-option-remove {
  color: #000000;
  margin: 2px 0px 3px 2px !important;
  font-size: 20px;
}

.css-z2hyoe-ItemComponent.react-dropdown-select-item-selected {
  background: #f4f3ff;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #111827;
}

.react-dropdown-select-option-label {
  margin-bottom: 2px;
}

.preview-section {
  padding: 24px 32px;
  background-color: var(--secondary-container-bg-color);
  border-bottom: 1px solid var(--primary-container-border-color);
  min-height: 112px;
}

.upload-section {
  background-color: var(--secondary-container-bg-color);
  border: 1px solid var(--primary-container-border-color);
  color: var(--secondary-font-color);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  --bs-gutter-x: none;
}

.file-upload {
  height: 200px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 50px;
}

.file-upload .upload-letter {
  color: #000 !important;
  font-size: 20px !important;
}
.file-upload .upload-formats {
  margin-top: 30px !important;
  color: #222 !important;
  font-size: 16px !important;
}

.react-file-uploader {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--secondary-font-color);
  margin-top: 19px;
}

.react-file-uploader label {
  bottom: 85px;
  width: 100%;
  height: 200px !important;
  margin: auto;
  max-width: none !important;
  opacity: 0;
}

.post-editor-modal .modal-footer {
  padding: 22px 32px;
}

.sun-editor {
  font-family: inter !important;
  font-size: 14px !important;
}

.sun-editor .se-resizing-bar.se-resizing-none {
  display: none;
}

.sun-editor .se-resizing-bar {
  display: none !important;
}

.sun-editor-editable * {
  font-family: inter !important;
  font-size: 16px !important;
}

.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  height: 300px !important;
  border-radius: 6px;
}

.sun-editor {
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.sun-editor .se-wrapper {
  border: none;
}

.sun-editor .se-toolbar {
  border-radius: 6px 6px 0px 0px;
  padding: 0px;
}

.sun-editor .se-menu-list li {
  background: #ffffff;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #f0f1f2;
  border-radius: 0px;
}

.sun-editor .se-btn-tray {
  padding: 16px 13px 10px 13px !important;
}

.sun-editor .se-menu-list li {
  background: #ffffff;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #f0f1f2;
  border-radius: 0px;
}

.tag-input-tag {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #5b21b6;
  padding: 2px 10px 4px 6px !important;
  background: #ede9fe !important;
  border-radius: 10px !important;
  flex-direction: row-reverse;
}

.tag-input-tag button {
  color: #111827;
  font-size: 12px;
}

.tag-input {
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--primary-button-color);
  width: 200px;
  background-color: #000000;
}

.tag-input::placeholder {
  color: var(--primary-button-color);
}

.rti--container {
  background: var(--text-input-bg-color) !important;
  border: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 6px !important;
}

@media screen and (max-width: 992px) {
  .post-editor-modal .modal-header {
    padding-top: 32px;
    padding-right: 24px;
    padding-left: 24px;
  }

  .post-editor-modal .modal-body {
    padding-right: 24px;
    padding-left: 24px;
  }
}
